import auth from '@/middleware/auth.js'

export default [
  {
    path: ''
    ,component: () => import('@/layouts/Constelacion')
    ,children: [
      {
        path: '/tickets'
        ,name: 'tickets'
        ,component: () => import('@/apps/tickets/Dashboard')
        ,meta: {
          title: 'Tickets'
          ,middleware: auth
        }
      }
      ,{
        path: '/tickets/listado'
        ,name: 'tickets_listado'
        ,component: () => import('@/apps/tickets/pages/Ticket')
        ,meta: {
          title: 'Listado - Tickets'
          ,middleware: auth
        }
      }
      ,{
        path: '/tickets/areas'
        ,name: 'tickets_areas'
        ,component: () => import('@/apps/tickets/pages/Area')
        ,meta: {
          title: 'Áreas - Tickets'
          ,middleware: auth
        }
      }
      ,{
        path: '/tickets/miarea/usuarios'
        ,name: 'tickets_usuarios'
        ,component: () => import('@/apps/tickets/pages/Usuario')
        ,meta: {
          title: 'Usuarios - Tickets'
          ,middleware: auth
        }
      }
      ,{
        path: '/tickets/miarea/etapas'
        ,name: 'tickets_etapas'
        ,component: () => import('@/apps/tickets/pages/Etapa')
        ,meta: {
          title: 'Etapas - Tickets'
          ,middleware: auth
        }
      }
      ,{
        path: '/tickets/miarea/requerimientos'
        ,name: 'tickets_requerimientos'
        ,component: () => import('@/apps/tickets/pages/Requerimiento')
        ,meta: {
          title: 'Requerimientos - Tickets'
          ,middleware: auth
        }
      }
    ]
  }
]